import { ChangeEvent, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import StickyNoteIcon from '@mui/icons-material/StickyNote2Outlined'
import SyncIcon from '@mui/icons-material/Sync'
import {
  Button,
  Container,
  Divider,
  InputAdornment,
  Stack,
  styled,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { find, isEmpty, startCase } from 'lodash'

import useConfig from 'api/config/useConfig'
import useEditOrder from 'api/order/useEditOrder'
import useSendOrderToSupplier from 'api/order/useSendToSupplier'
import useSuppliers from 'api/order/useSuppliers'
import useUpdateCustomerInfo from 'api/order/useUpdateCustomerInfo'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import { LoadingButton } from 'components/Buttons'
import EditableTextField from 'components/Form/EditableTextField'
import { FieldsGroup } from 'components/FormHelpers'
import { ReadonlyField } from 'components/ReadonlyField'
import { Link } from 'components/StyledLink'
import { Switch } from 'components/Switch'
import { Can } from 'context/Ability'
import { localeDateTime } from 'utils/date'
import { AddressType, OrderUpdate, ShippingMethod } from 'utils/global-types'
import {
  getPaymentStatusColor,
  getShipmentStatusColor,
  getStatusLabel,
} from 'utils/order'
import { formatPercent } from 'utils/price'

import SupplierAutocomplete from './SupplierAutocomplete'

const Details = styled(Container)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0,1fr))',
  padding: '24px 52px 0',
  columnGap: '25px',
  rowGap: 16,
  justifyContent: 'space-between',
})

const ColumnTitle = styled(Typography)({
  fontWeight: 500,
})

const iconRotation: SxProps = {
  animation: 'spin 1s linear infinite',
}

type ReadonlyAddressProps = {
  type: AddressType
  order: OrderUpdate
}

const ReadonlyAddress = ({ type, order }: ReadonlyAddressProps) => (
  <ReadonlyField label={`${startCase(type)} address`}>
    {order[`${type}_organization_name`] && (
      <Typography lineHeight="1.75">
        {order[`${type}_organization_name`]},
      </Typography>
    )}
    <Typography lineHeight="1.75">
      {order[`${type}_address_line_1`] || '-'}
    </Typography>
    <Typography lineHeight="1.75">
      {order[`${type}_address_line_2`] || '-'}
    </Typography>
    <Typography lineHeight="1.75">
      {order[`${type}_postal_code`]} {order[`${type}_city`]}
    </Typography>
    <Typography lineHeight="1.75">{order[`${type}_country`]}</Typography>
  </ReadonlyField>
)

interface OrderGeneralProps {
  order: OrderUpdate
  shippingMethod?: ShippingMethod
}

export default function OrderGeneral({
  order,
  shippingMethod,
}: OrderGeneralProps) {
  const { id: orderID } = useParams()

  const { data } = useConfig()
  const { data: suppliers } = useSuppliers()
  const { data: productItems } = useOrderProductItems(orderID)

  const currentSupplier = useMemo(
    () => find(suppliers, ({ uuid }) => uuid === order?.supplier),
    [suppliers, order?.supplier]
  )

  const config = data!
  const vatPercent = formatPercent(config.VAT.value as number)

  const showAccountNumber = shippingMethod?.rate_calculation_type === 'no-fees'
  const isSendToSupplierDisabled =
    !productItems?.length ||
    !!order.supplier_order_id ||
    isEmpty(currentSupplier?.handler)

  const { mutate: editOrder } = useEditOrder(orderID!)
  const { mutate: updateCustomerInfo, isLoading: isCustomerUpdates } =
    useUpdateCustomerInfo(orderID!)
  const { mutate: sendOrderToSupplier, isLoading: isSendToSupplierLoading } =
    useSendOrderToSupplier(orderID!)

  const toggleBlockShipping = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    editOrder({ is_blocked_shipping: checked })
  }

  const toggleChargeVAT = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    editOrder({ is_charge_vat: checked })
  }

  const handlePONumberChange = (value: string) => {
    if (order.po_number !== value) {
      editOrder({ po_number: value })
    }
  }

  const handleAmountVEGChange = (value: string) => {
    const newValue = Number(value)
    if (order.supplier_cost !== newValue) {
      editOrder({ supplier_cost: Number(value) })
    }
  }

  const handleSupplierOrderIdChange = (value: string) => {
    if (order.supplier_order_id !== value) {
      editOrder({ supplier_order_id: value })
    }
  }

  const handleSendToSupplier = () => {
    if (order.supplier) {
      sendOrderToSupplier({ order: order.uuid, supplier: order.supplier })
    }
  }

  const handleUpdateCustomer = () => {
    updateCustomerInfo()
  }

  return (
    <Details>
      {!order.is_latest_updates && (
        <Stack gridColumn="1/4" direction="row" justifyContent="end">
          <Button
            variant="outlined"
            disabled={isCustomerUpdates}
            onClick={handleUpdateCustomer}
            startIcon={
              <SyncIcon sx={isCustomerUpdates ? iconRotation : undefined} />
            }
          >
            Latest updates
          </Button>
        </Stack>
      )}
      <Container>
        <ColumnTitle sx={{ lineHeight: '56px', height: '56px' }}>
          Customer details
        </ColumnTitle>
        <Divider />
        <Stack gap={2} mt={1}>
          <ReadonlyField label="Name">
            <Stack direction="row" gap={1}>
              <Link to={`/customers/${order.customer}`}>
                {order.customer_name}
              </Link>
              {order.customer_notes && (
                <Tooltip title={order.customer_notes}>
                  <StickyNoteIcon color="info" />
                </Tooltip>
              )}
            </Stack>
          </ReadonlyField>
          <ReadonlyField label="Email">{order.customer_email}</ReadonlyField>
          <ReadonlyField label="Organization">
            <Stack direction="row" gap={1}>
              <Link to={`/organizations/${order.organization.uuid}`}>
                {order.organization.name}
              </Link>
              {order.organization?.notes && (
                <Tooltip title={order.organization?.notes}>
                  <StickyNoteIcon color="info" />
                </Tooltip>
              )}
            </Stack>
          </ReadonlyField>
          <ReadonlyField label="Phone number">
            {order.customer_phone_number}
          </ReadonlyField>
          <ReadonlyField label="Order Note">{order.notes}</ReadonlyField>
        </Stack>
      </Container>
      <Container>
        <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Container>
            <ColumnTitle>Shipping</ColumnTitle>
            <Typography
              sx={{ textTransform: 'uppercase' }}
              variant="subtitle2"
              color={getShipmentStatusColor(order.shipment_status)}
            >
              {getStatusLabel(order.shipment_status)}
            </Typography>
          </Container>
          <Switch
            label="Block shipping"
            sx={{ minWidth: 80 }}
            defaultChecked={order.is_blocked_shipping}
            onChange={toggleBlockShipping}
          />
        </Container>
        <Divider />
        <Stack gap={2} mt={1}>
          <ReadonlyAddress type="shipping" order={order} />
          {order.shipping_attn && (
            <ReadonlyField label="ATTN">{order.shipping_attn}</ReadonlyField>
          )}
          <ReadonlyField label="Shipping instructions">
            {order.shipping_notes}
          </ReadonlyField>
          <ReadonlyField label="Shipping method">
            {shippingMethod?.name}
          </ReadonlyField>
          {showAccountNumber && (
            <ReadonlyField label="Shipping account number">
              {order.shipping_instructions || '-'}
            </ReadonlyField>
          )}
        </Stack>
      </Container>
      <Container>
        <Stack direction="row" justifyContent="space-between" pb="7px">
          <Stack>
            <ColumnTitle>Billing</ColumnTitle>
            <Typography
              sx={{ textTransform: 'uppercase' }}
              variant="subtitle2"
              color={getPaymentStatusColor(order.payment_status)}
            >
              {getStatusLabel(order.payment_status)}
            </Typography>
          </Stack>
          <ReadonlyField
            label="Amt. outstanding"
            sx={{ flexBasis: 100, mb: -1 }}
          >
            {order.outstanding_amount}
          </ReadonlyField>
        </Stack>
        <Divider />
        <Stack gap={2} mt={1}>
          <ReadonlyAddress type="billing" order={order} />
          <FieldsGroup sameWidth>
            <ReadonlyField label="VAT number">{order.vat_number}</ReadonlyField>
            <Switch
              label={`Charge VAT (${vatPercent})`}
              sx={{ mt: -0.75 }}
              readOnly
              defaultChecked={order.is_charge_vat}
              onChange={toggleChargeVAT}
            />
          </FieldsGroup>
          <FieldsGroup sameWidth>
            <ReadonlyField label="Advance %">
              {order.advance_limit}
            </ReadonlyField>
            <ReadonlyField label="Upon ship.%">
              {order.upon_shipping_limit}
            </ReadonlyField>
            <ReadonlyField label="NET %">{order.net_limit}</ReadonlyField>
            <ReadonlyField label="NET days">
              {order.max_outstanding_days}
            </ReadonlyField>
          </FieldsGroup>
        </Stack>
      </Container>
      <Divider sx={{ gridColumn: '1/4', mb: 1 }} />
      <Stack direction="row" justifyContent="space-between" gridColumn="1/4">
        <FieldsGroup sameWidth width="100%">
          <EditableTextField
            label="PO Number"
            sx={{ width: 400 }}
            onChange={handlePONumberChange}
            value={order.po_number || ''}
          />
          <SupplierAutocomplete
            value={currentSupplier}
            orderID={order.order_id}
            disabled={!!order.sent_to_supplier}
          />
          <EditableTextField
            label="Supplier order ID"
            sx={{ width: '50%' }}
            value={order.supplier_order_id}
            onChange={handleSupplierOrderIdChange}
          />
          <EditableTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">EUR</InputAdornment>
              ),
            }}
            label="Supplier amount"
            placeholder="Amount"
            value={order.supplier_cost}
            onChange={handleAmountVEGChange}
          />
        </FieldsGroup>
        <Can I="place" an="order">
          <Stack rowGap={0.75}>
            <LoadingButton
              variant="contained"
              sx={{ minWidth: 160 }}
              disabled={isSendToSupplierDisabled}
              isLoading={isSendToSupplierLoading}
              onClick={handleSendToSupplier}
            >
              Send to supplier
            </LoadingButton>
            {order.sent_to_supplier && (
              <Typography
                variant="caption"
                whiteSpace="nowrap"
                color="text.secondary"
              >
                Date ordered: {localeDateTime(order.sent_to_supplier)}
              </Typography>
            )}
          </Stack>
        </Can>
      </Stack>
    </Details>
  )
}
