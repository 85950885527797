import {
  Invoice,
  InvoiceProductBundleItem,
  OrderProductItemList,
  OrderUpdate,
} from 'utils/global-types'
import { getAvailableQuantity } from 'utils/order'

import {
  CalculatedInvoiceProductItem,
  CombinedProductItem,
  InvoiceForm,
} from './types'

export const getCombinedProductItems = (
  orderProductItems: OrderProductItemList[],
  invoiceProductItems?: InvoiceProductBundleItem[],
  calculatedInvoiceProductItems?: CalculatedInvoiceProductItem[]
): CombinedProductItem[] => {
  return orderProductItems.map((orderProductItem) => {
    return {
      order: orderProductItem,
      invoice: invoiceProductItems?.find(
        (invoiceProductItem) => invoiceProductItem.sku === orderProductItem.sku
      ),
      calculated: calculatedInvoiceProductItems?.find(
        (calculatedInvoiceProductItem) =>
          calculatedInvoiceProductItem.sku === orderProductItem.sku
      ),
    }
  })
}

export const getDefaultValues = (
  order: OrderUpdate,
  combinedProductItems: CombinedProductItem[],
  invoice?: Invoice
): InvoiceForm => {
  const source = invoice === undefined ? order : invoice

  return {
    discount_lump_sum: source.discount_lump_sum,
    margin_percent_overwrite: source.margin_percent_overwrite,
    handling_fee: source.handling_fee,
    shipping_address: source.shipping_address,
    shipping_fee_overwrite: source.shipping_fee_overwrite,
    shipping_attn: source.shipping_attn,
    shipping_notes: source.shipping_notes,
    shipping_method: source.shipping_method,
    shipping_instructions: source.shipping_instructions,
    billing_address: source.billing_address,
    payment_terms: invoice
      ? invoice.payment_terms
      : order.payment_terms_display,
    notes: invoice ? invoice.notes : '',
    invoice_items: combinedProductItems
      .filter(({ order: orderItem, invoice: invoiceItem }) =>
        getAvailableQuantity(orderItem, invoiceItem)
      )
      .map(({ order: orderItem, invoice: invoiceItem }) => {
        // Invoice can exist, but can not includes all order items
        const itemSource = invoiceItem === undefined ? orderItem : invoiceItem

        return {
          sales_price: itemSource.sales_price || itemSource.web_price_display,
          order_product_item: invoiceItem ? undefined : orderItem.uuid,
          invoice_product_item: invoiceItem?.uuid,
          buying_price: itemSource.buying_price,
          buying_price_original: itemSource.buying_price_original,
          quantity_available: getAvailableQuantity(orderItem, invoiceItem),
          quantity_offered: invoiceItem
            ? invoiceItem.quantity_offered
            : invoice
            ? 0
            : orderItem.quantity_available,
          notes: itemSource.notes,
          is_details: !!itemSource.is_details,
        }
      }),
  }
}
