import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  alpha,
  Box,
  Divider,
  InputAdornment,
  Stack,
  styled,
  Switch,
  SxProps,
  Typography,
} from '@mui/material'

import productPlaceholderSmall from 'assets/img/productPlaceholderSmall.png'
import { ExternalLink } from 'components/ExternalLink'
import ControlledInput from 'components/Form/ControlledInput'
import { ReadonlyTextField } from 'components/Form/EditableTextField'
import { ReadonlyField } from 'components/ReadonlyField'
import { Link } from 'components/StyledLink'
import PriceInSystemCurrency from 'pages/QuoteEdit/PriceInSystemCurrency'
import { Currency } from 'utils/global-types'
import { formatGramsToKg } from 'utils/helpers'
import {
  buyingPriceInputOptions,
  formatPercent,
  formatPrice,
  salesPriceInputOptions,
} from 'utils/price'
import {
  getPriceLabel,
  getShortProductLabel,
  ProductOption,
} from 'utils/product'

import InvoiceProductItemStatusLine from './InvoiceProductItemStatusLine'
import { InvoiceDisplayDataItem } from './types'

export type InvoiceProductItemProps = InvoiceDisplayDataItem & {
  index: number
  currency: Currency
}

const ItemWrapper = styled(Box)(({ theme }) => ({
  padding: 8,
  display: 'flex',
  columnGap: 8,
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.spacing(1),

  '& .MuiTextField-root': {
    marginBottom: 0,
  },
}))

const styles: Record<string, SxProps> = {
  priceFieldStyles: {
    '& p': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
      paddingBottom: 0,
      lineHeight: 1,
      marginTop: '3px',
    },
    '& span': {
      width: '100%',
      textAlign: 'right',
    },
  },
  totalFieldStyles: {
    mt: 2,
    minWidth: '150px',
    '& p': {
      whiteSpace: 'nowrap',
      textAlign: 'right',
      paddingBottom: 0,
      fontSize: '18px',
      lineHeight: 1,
    },
    '& span': {
      width: '100%',
      textAlign: 'right',
    },
  },
  smallInputField: {
    maxWidth: '12%',
    minWidth: 120,
    paddingBottom: 0,
  },
  quantityDetails: {
    alignSelf: 'flex-start',
    borderRadius: 1,
    padding: '8px 12px',
    '& > .MuiContainer-root': {
      transform: 'translateY(8px)',
    },
  },
  detailsSwitch: {
    position: 'absolute',
    left: '54px',
    top: '-6px',
  },
  notes: {
    mb: '0!important',
    flex: 2,
  },
}

export default function InvoiceProductItem(props: InvoiceProductItemProps) {
  const {
    order,
    index,
    listPrice,
    quantityAvailable,
    currency,
    salesPrice,
    salesPriceRelevant,
    salesPriceOriginal,
    listPriceDisplay,
    webPriceOriginal,
    totalPrice,
    buyingPriceOriginal,
    buyingPrice,
    totalPriceDisplay,
    marginPercent,
  } = props

  const [isSalesPriceOverwrite, setIsSalesPriceOverwrite] = useState(
    salesPrice === salesPriceRelevant
  )

  useEffect(() => {
    if (salesPrice === salesPriceOriginal) {
      setIsSalesPriceOverwrite(false)
    }
  }, [salesPrice, salesPriceOriginal])

  const {
    sku,
    product_unit,
    product_amount,
    type,
    image,
    series,
    hs_code,
    dimensions,
    discount_value,
    discount_group,
    weight_overwrite,
    product_category,
    country_of_origin,
    quantity_offered,
    quantity_reserved,
    quantity_invoiced,
    quantity_delivered,
    omnical_link,
    van_egmond_link,
    octopart_link,
    details_original,
  } = order

  const prices = {
    salesPrice: formatPrice(salesPrice, currency),
    listPrice: formatPrice(listPriceDisplay, currency),
    listPriceSystemCurrency: formatPrice(listPrice, 'eur'),
    listPriceOverwrite: formatPrice(listPrice, 'eur'),
    buyingPrice: formatPrice(buyingPrice, 'eur'),
    buyingPriceOriginal: formatPrice(buyingPriceOriginal, 'eur'),
    webPriceSystemCurrency: formatPrice(webPriceOriginal, 'eur'),
    webPriceOrderCurrency: formatPrice(salesPriceOriginal, currency),
    totalSalesPrice: formatPrice(totalPriceDisplay, currency),
    totalSalesPriceSystemCurrency: formatPrice(totalPrice, 'eur'),
  }

  const priceLabelOptions: ProductOption = {
    unit: product_unit,
    amount: product_amount,
  }

  const noQuantityAvailable = quantityAvailable === 0

  const getFieldName = (fieldName: string) =>
    `invoice_items.${index}.${fieldName}`

  const renderInputPrices = () => (
    <>
      <ControlledInput
        label="QTY to invoice"
        name={getFieldName('quantity_offered')}
        type="number"
        rules={{
          validate: (value) =>
            value <= quantityAvailable ||
            'Quantity cannot be more than requested',
        }}
        sx={styles.smallInputField}
        inputProps={{ min: 0, max: quantityAvailable }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              / {quantityAvailable}
            </InputAdornment>
          ),
        }}
      />
      <ControlledInput
        label={getPriceLabel('Buying price', priceLabelOptions)}
        name={getFieldName('buying_price')}
        type="number"
        inputProps={buyingPriceInputOptions}
        sx={{ pb: 0 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">EUR</InputAdornment>,
        }}
        helperText={
          <PriceInSystemCurrency
            systemCurrencyPrice={prices.buyingPriceOriginal}
            alwaysShow
            additionalText="Original:"
          />
        }
      />
      {isSalesPriceOverwrite ? (
        <ControlledInput
          label={getPriceLabel('Sales price', priceLabelOptions)}
          name={getFieldName('sales_price')}
          type="number"
          inputProps={salesPriceInputOptions}
          defaultValue={salesPrice}
          sx={{ pb: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.toUpperCase()}
              </InputAdornment>
            ),
          }}
          helperText={
            <PriceInSystemCurrency
              originalPrice={prices.webPriceOrderCurrency}
              systemCurrencyPrice={prices.webPriceSystemCurrency}
              additionalText="Original:"
              oneRowPrice
            />
          }
        />
      ) : (
        <ReadonlyTextField
          value={salesPriceRelevant?.toString()}
          label={getPriceLabel('Sales price', priceLabelOptions)}
          handleClick={() => setIsSalesPriceOverwrite(true)}
          startAdornment={
            <InputAdornment position="start">
              {currency.toUpperCase()}
            </InputAdornment>
          }
          helperText={
            <PriceInSystemCurrency
              originalPrice={prices.webPriceOrderCurrency}
              systemCurrencyPrice={prices.webPriceSystemCurrency}
              additionalText="Original:"
              oneRowPrice
            />
          }
        />
      )}
    </>
  )

  const renderReadOnlyPrices = () => (
    <>
      <ReadonlyField
        label={getPriceLabel('Buying price', priceLabelOptions)}
        sx={styles.priceFieldStyles}
      >
        {prices.buyingPrice}

        <PriceInSystemCurrency
          systemCurrencyPrice={prices.buyingPriceOriginal}
          alwaysShow
          additionalText="Original:"
        />
      </ReadonlyField>
      <ReadonlyField
        label={getPriceLabel('Sales price', priceLabelOptions)}
        sx={styles.priceFieldStyles}
      >
        {prices.listPriceOverwrite}

        <PriceInSystemCurrency
          originalPrice={prices.webPriceOrderCurrency}
          systemCurrencyPrice={prices.webPriceSystemCurrency}
          additionalText="Original:"
          oneRowPrice
        />
      </ReadonlyField>
      <Divider orientation="vertical" variant="middle" flexItem />
    </>
  )

  return (
    <ItemWrapper
      sx={{
        backgroundColor: (theme) =>
          noQuantityAvailable
            ? theme.palette.grey[50]
            : theme.palette.background.paper,
      }}
    >
      <InvoiceProductItemStatusLine noQuantityAvailable={noQuantityAvailable} />
      <Stack gap={2} padding={1} flexGrow={1}>
        <Stack direction="row">
          <Typography fontWeight="bold" width={24}>
            {index + 1}
          </Typography>
          <Box
            component="img"
            src={image || productPlaceholderSmall}
            sx={{ width: 48, height: 48, mr: 2 }}
          />
          <Stack flexGrow={1}>
            <Link to={`/products/${sku}`}>
              <Typography fontWeight="bold">
                {getShortProductLabel(order)}
              </Typography>
            </Link>
            <Typography color="text.secondary">
              {series} {type} {product_category}
            </Typography>
          </Stack>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ ml: 3, mt: 1 }}
          >
            <ExternalLink href={omnical_link} target="_blank">
              Omnical
            </ExternalLink>{' '}
            |{' '}
            <ExternalLink href={van_egmond_link} target="_blank">
              Van Egmond
            </ExternalLink>{' '}
            |{' '}
            <ExternalLink href={octopart_link} target="_blank">
              Octopart
            </ExternalLink>
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          columnGap={3}
          pl={3}
        >
          <ReadonlyField minWidth={120} label="Product contains">
            {product_amount} {product_unit}
          </ReadonlyField>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ReadonlyField minWidth={120} label="Weight (kg)">
            {formatGramsToKg(weight_overwrite)}
          </ReadonlyField>
          <ReadonlyField minWidth={120} label="Dimensions (LWH)">
            {dimensions}
          </ReadonlyField>
          <ReadonlyField minWidth={120} label="Country">
            {country_of_origin}
          </ReadonlyField>
          <ReadonlyField label="HS">{hs_code}</ReadonlyField>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ReadonlyField
            label={getPriceLabel('List price', priceLabelOptions)}
            sx={styles.priceFieldStyles}
          >
            {prices.listPrice}
            <PriceInSystemCurrency
              systemCurrencyPrice={prices.listPriceSystemCurrency}
              originalPrice={prices.listPrice}
            />
          </ReadonlyField>
          <ReadonlyField label="Discount" sx={styles.priceFieldStyles}>
            {formatPercent(discount_value)}
            <Typography variant="caption" color="text.secondary">
              ({discount_group})
            </Typography>
          </ReadonlyField>
          <ReadonlyField label="Item margin" sx={styles.priceFieldStyles}>
            {marginPercent.toFixed(1)}%
          </ReadonlyField>
        </Stack>

        <Stack pl={3} gap={3} direction="row" justifyContent="space-between">
          <Stack
            direction="row"
            columnGap={1}
            sx={{
              ...styles.quantityDetails,
              backgroundColor: (theme) =>
                noQuantityAvailable
                  ? alpha(theme.palette.text.primary, 0.04)
                  : alpha(theme.palette.primary.main, 0.1),
            }}
          >
            <ReadonlyField minWidth={56} label="Ordered">
              {quantity_offered}
            </ReadonlyField>
            <ReadonlyField minWidth={56} label="Invoiced">
              {quantity_invoiced}
            </ReadonlyField>
            <ReadonlyField minWidth={56} label="Reserved">
              {quantity_reserved}
            </ReadonlyField>
            <ReadonlyField minWidth={56} label="Delivered">
              {quantity_delivered}
            </ReadonlyField>
          </Stack>
          <Stack
            direction="row"
            columnGap={3}
            justifyContent="space-around"
            flexGrow={1}
            mt={2}
          >
            {noQuantityAvailable ? renderReadOnlyPrices() : renderInputPrices()}
          </Stack>
          <ReadonlyField label="Total" sx={styles.totalFieldStyles}>
            <Typography fontWeight="bold">{prices.totalSalesPrice}</Typography>
            <PriceInSystemCurrency
              systemCurrencyPrice={prices.totalSalesPriceSystemCurrency}
              originalPrice={prices.totalSalesPrice}
            />
          </ReadonlyField>
        </Stack>
        <Stack direction="row" pb={3}>
          <ReadonlyField label="Details" sx={{ flex: 1, position: 'relative' }}>
            {details_original}
            <Controller
              name={getFieldName('is_details')}
              render={({ field }) => (
                <Switch
                  {...field}
                  size="small"
                  sx={styles.detailsSwitch}
                  defaultChecked={field.value}
                />
              )}
            />
          </ReadonlyField>
          <ControlledInput
            name={getFieldName('notes')}
            label="Notes (optional)"
            multiline
            sx={styles.notes}
            minRows={3}
          />
        </Stack>
      </Stack>
    </ItemWrapper>
  )
}
